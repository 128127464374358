/* You can add global styles to this file, and also import other style files */
// @import "~bootstrap/dist/css/bootstrap.css"
@import '~@angular/material/prebuilt-themes/indigo-pink.css';

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.div-padding-left {
  padding-left: 17%;
  width: 100%;
}

.mat-table {
  overflow-x: scroll;
}

mat-form-field {
  width: 100%;
}

button {
  cursor: pointer;
}

.cursor_point {
  cursor: pointer;
}

.mat-warn {
  background-color: #da291c;
  color: #fff;
}

.mat-accent {
  color: #da291c;
  cursor: pointer;
}

.mat-archive {
  color: #da291c;
  cursor: pointer;
}
.mat-circle {
  color: #4dcff1;
  cursor: pointer;
}
.mat-brightness_1 {
  color: #2be82b;
  cursor: pointer;
}
.mat-lens {
  color: #00000029;
  cursor: pointer;
}
mat-checkbox {
  background: transparent;
  color: black !important;
}

@media print {
  .isPrinting > * { display: none; } /* hide print-layout since the institution_id is not available */
  .isPrinting app-print-layout { display: block; } /* display app-print-layout for printing*/
}
